import logo from './logo.svg';
import logo2 from './logo.png';
import './App.css';
import { 
  AppProvider, 
  TextField,
  Button,
} from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";
import { useState, useCallback } from 'react';
import validator from "validator";
import { compressToBase64, decompressFromBase64 } from "lz-string";
import Papa from 'papaparse';

function App() {
  const VALIDATE_EMAIL_URL = 'https://ea-aff-2528cd765741.herokuapp.com/api/validateEmail';
  // const reasonTitles = {
  //   mx: 'MX (invalid email server records)',
  //   regex: 'Regex (formatting)',
  //   typo: 'Typo',
  //   smtp: 'SMTP',
  //   disposable: 'Disposable (temporary email)',
  // };

  const [emailResult, setEmailResult] = useState(false);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [emailError, setEmailError] = useState(false);  
  const [email, setEmail] = useState('');
  const handleChange = useCallback(
    (newValue) => setEmail(newValue),
    [],
  );
  const handleClearButtonClick = useCallback(() => setEmail(''), []);

  async function submitEmail() {
    try {
      setEmailError(false); // reset error
      setFieldDisabled(true);

      setEmail(email.trim());

      if (email && email.split(",") && email.split(",").length > 1) {
        // skip check b/c multiple emails
      } else if (!email) {
        setEmailError('Enter an email');
        setFieldDisabled(false);
        return false;
      } else if (!validator.isEmail(email)) {
        setEmailError('Invalid email format');
        setFieldDisabled(false);
        return false;
      }
      
      let result;
      try {
        result = await validateEmail(compressToBase64(email));
        if (result && result.result) {
          result = JSON.parse( decompressFromBase64(result.result) );
        } else {
          result = false;
        }        
      } catch(err) {
        console.log("ERR1:", err);
      }

      // console.log(result?.length, result);

      if (result && result.length) {
        if (result.length === 1) {
          setEmailResult(result[0]);
        } else {
          setEmailResult(result);  
        }        
      } else {
        setEmailError("Error, please check your input and try again");
      }

      setFieldDisabled(false);
    } catch(err2) {
      console.log("ERR2:", err2);
    }
  } // end func

  function downloadToCSV(data) {
    try {
      let dataCopy = data.slice();
      dataCopy.map((item, i) => {
        try {
          dataCopy[i] = {...item, ...item.validators};
          delete dataCopy[i].validators;
          if (dataCopy[i].valid) dataCopy[i].reason = 'valid';
          } catch(err44) {
          console.log(err44);
        }
        return true;
      });
      dataCopy.map((item, i) => {
        try {
          if (dataCopy[i].regex.valid) {
            dataCopy[i].regex = 'valid';
          } else {
            dataCopy[i].regex = dataCopy[i].regex.reason ?? 'invalid';
          }
          if (dataCopy[i].mx.valid) {
            dataCopy[i].mx = 'valid';
          } else {
            dataCopy[i].mx = dataCopy[i].mx.reason ?? 'invalid';
          }
          if (dataCopy[i].typo.valid) {
            dataCopy[i].typo = 'valid';
          } else {
            dataCopy[i].typo = dataCopy[i].typo.reason ?? 'invalid';
          }
          if (dataCopy[i].smtp.valid) {
            dataCopy[i].smtp = 'valid';
          } else {
            dataCopy[i].smtp = dataCopy[i].smtp.reason ?? 'invalid';
          }
          if (dataCopy[i].disposable.valid) {
            dataCopy[i].disposable = 'valid';
          } else {
            dataCopy[i].disposable = dataCopy[i].disposable.reason ?? 'invalid';
          }
        } catch(err33) {
          console.log(err33);
        }
        return true;
      });

      const csvData = Papa.unparse(dataCopy);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'NoBounceEmails.com.csv';
      a.click();
      URL.revokeObjectURL(url);
    } catch(err) {
      console.log(err);
    }
   
  } // end func

  async function validateEmail(emailVal) {
    try {
      const response = await fetch(VALIDATE_EMAIL_URL, {
        method: 'POST', // Specify the HTTP method
        headers: {
          'Content-Type': 'application/json', // Set the content type as needed
        },
        body: JSON.stringify({email: emailVal}),
      });
      if (!response || !response.ok) {
        console.log('ERR 101:', response);
      } else {
        const data = await response.json();
        return data;
      }      
    } catch (error) {
      console.error('Error:', error);
    }
    return false;
  } // end func

  return (
    <AppProvider i18n={translations}>
    
    <img src={logo2} className="App-logo" alt="logo" />                  

    <br /><br />

    <div className="mainContainer">

      <div className="inputDiv maxHeightTextField">        
        <TextField
          label="Enter Emails to Validate:"
          type="email"
          placeholder='Enter emails here, seperate multiple emails with commas ","'
          value={email}
          error={emailError}
          onChange={handleChange}
          clearButton
          onClearButtonClick={handleClearButtonClick}
          onKeyDown={(e) => e.which === 13 ? submitEmail() : ''}
          autoComplete="off"
          disabled={fieldDisabled}    
          multiline={4}     
          connectedRight={
            <>
              <Button 
                size="large"
                onClick={submitEmail}
                disabled={!email}
                loading={fieldDisabled}
              >
                Validate
              </Button>

              <br />

              <span>
              {`${email && email.split(",") && email.split(",").length > 1 ? 
                (email.split(",")[email.split(",").length-1].trim() 
                  ? email.split(",").length + ' emails' : (email.split(",").length-1).toLocaleString() + (email.split(",").length-1 === 1 ? ' email' :' emails')) 
                : ''
              }`}
              </span>
            </>
          }
        />
      </div>

      {
        emailResult ? 
        emailResult.length > 1 ? 

          <div className="validationInfo">      
            {emailResult.toReversed().slice(0, 5).map((emailRes, i) => (
              <p key={`email-${i}`} className="spaceBetween"><span className="listItemResultLeft">{emailRes.email}</span> {emailRes.valid ? <span className="c-green listItemResultRight">Valid</span> : <span className="c-red listItemResultRight">{emailRes.validators[emailRes.reason].reason}</span>}</p>
            ))}         
            {emailResult.length > 5 ? <p className="text-right">Showing 5 of {emailResult.length} emails</p> : ''}

            <br />

            <div className="text-center mt-1">
              <Button size="large" onClick={() => downloadToCSV(emailResult)} >Download All Results to CSV</Button>
            </div>

          </div>

        : 
          <div className="validationInfo">              
            <div className={`${emailResult.valid ? 'bg-green' : 'bg-red'}`}>
              <p className={`bold resultText ${emailResult.valid ? '' : 'pb-0'}`}>This email is {emailResult.valid ? 'Valid' : 'Invalid'}</p>                    
              {emailResult.reason ? <p className="resultText m-0 pt-0 pb-1">{emailResult.validators[emailResult.reason].reason} </p> : ''}
            </div>
            
            <br />
            
            <p className="bold pb-1">Validation Tests</p>
            <p className="spaceBetween"><span className="listItemResultLeft">MX (DNS):</span> {emailResult.validators?.mx.valid ? <span className="c-green listItemResultRight">Valid</span> : <span className="c-red listItemResultRight">{emailResult.validators.mx.reason ?? 'Invalid'}</span>}</p>
            <p className="spaceBetween"><span className="listItemResultLeft">Regex (formatting):</span> {emailResult.validators?.regex.valid ? <span className="c-green listItemResultRight">Valid</span> : <span className="c-red listItemResultRight">{emailResult.validators.regex.reason ?? 'Invalid'}</span>}</p>
            <p className="spaceBetween"><span className="listItemResultLeft">SMTP (server):</span> {emailResult.validators?.smtp.valid ? <span className="c-green listItemResultRight">Valid</span> : <span className="c-red listItemResultRight">{emailResult.validators.smtp.reason ?? 'Invalid'}</span>}</p>
            <p className="spaceBetween"><span className="listItemResultLeft">Typo:</span> {emailResult.validators?.typo.valid ? <span className="c-green listItemResultRight">Valid</span> : <span className="c-red listItemResultRight">{emailResult.validators.typo.reason ?? 'Invalid'}</span>}</p>
            <p className="spaceBetween"><span className="listItemResultLeft">Disposable:</span> {emailResult.validators?.disposable.valid ? <span className="c-green listItemResultRight">Valid</span> : <span className="c-red listItemResultRight">{emailResult.validators.disposable.reason ?? 'Invalid'}</span>}</p>

          </div>
        : ''}

      </div>               
      
    </AppProvider>
  );
}

export default App;
